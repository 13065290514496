<template>
  <div class="storage-table mt-4">
    <app-table
      :loading="loading"
      :data="data ? data : []"
      :fields="fields"
      :rules="[]"
      @reload="reloadData"
    />
  </div>
</template>

<script>
import { AppTable } from '@/app/shared/components';
import { mapActions, mapGetters } from 'vuex';
import { LOADING, DATA, ERROR } from '../shared/state/types/getter_types';
import { FETCH_STORAGE } from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';
import { appLocalStorage } from '../../shared/services';

export default {
  name: 'StorageTable',
  components: { AppTable },
  mixins: [notifyMixin],
  data() {
    return {
      fields: [
        { label: '#', value: 'id', width: 50 },
        { label: 'Наименование', value: 'ingredient.title', width: 200 },
        { label: 'Категория', value: 'ingredient.category.title' },
        { label: 'Ед. измерения', value: 'ingredient.unit.title' },
        { label: 'Остатки на складах', value: 'count' },
        { label: 'Себестоимость', value: 'cost_price' },
      ],
      modal: {
        show: false,
        title: null,
      },
      filter: appLocalStorage.getItem('filters').storage,
    };
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      data: DATA,
      error: ERROR,
    }),
  },
  async mounted() {
    await this.fetch({ filter: this.filter.value });
  },
  methods: {
    ...mapActions({ fetch: FETCH_STORAGE }),
    async reloadData(page) {
      await this.fetch({ page, filter: this.filter.value });
    },
  },
};
</script>
