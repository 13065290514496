<template>
  <div class="storage">
    <storage-filter />
    <storage-table />
  </div>
</template>

<script>
import StorageFilter from './storage-filter/storage-filter.vue';
import StorageTable from './storage-table/storage-table.vue';

export default {
  name: 'Storage',
  components: { StorageTable, StorageFilter },
};
</script>
