<template>
  <div class="storage-filter">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-badge is-dot :hidden="filter.hidden" class="item">
          <el-button icon="el-icon-set-up" @click="activeFilter"> Фильтр </el-button>
        </el-badge>
      </el-col>
    </el-row>
    <div v-show="!filter.hidden" class="filter-panel mt-4">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            v-model="filter.value['ingredient.title']"
            prefix-icon="el-icon-search"
            placeholder="Фильтр по названию ингредиента"
            @input="searchFn"
          />
        </el-col>
        <el-col :span="8">
          <el-autocomplete
            v-model="filter.value['ingredient.category.title']"
            style="width: 100%"
            :fetch-suggestions="searchCategoryFn"
            :debounce="600"
            prefix-icon="el-icon-search"
            placeholder="Фильтр по категории"
            value-key="title"
            @select="selectCategoryFn"
            @input="clearSearchCategory"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { appLocalStorage } from '@/app/shared/services';
import { FETCH_INGREDIENT_CATEGORY } from '@/app/administration/ingredients/shared/state/types/action_types';
import { CATEGORY as DATA_CATEGORY } from '@/app/administration/ingredients/shared/state/types/getter_types';
import { FETCH_MEALS } from '@/app/settings/meals/shared/state/types/action_types';
import { DATA as DATA_MEALS } from '@/app/settings/meals/shared/state/types/getter_types';
import { FETCH_STORAGE } from '../shared/state/types/action_types';

export default {
  name: 'StorageFilter',
  data() {
    return {
      isActiveFilter: true,
      branch: null,
      title: null,
      initStorage: appLocalStorage.getItem('filters'),
      filter: appLocalStorage.getItem('filters').storage,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({ categories: DATA_CATEGORY, meals: DATA_MEALS }),
  },
  beforeCreate() {
    const initStorage = appLocalStorage.getItem('filters')
      ? appLocalStorage.getItem('filters')
      : {};
    if (!initStorage.storage) {
      appLocalStorage.setItem(
        'filters',
        Object.assign(initStorage, { storage: { hidden: true, value: {} } })
      );
    }
  },
  methods: {
    ...mapActions({
      fetch: FETCH_STORAGE,
      fetchCategories: FETCH_INGREDIENT_CATEGORY,
      fetchMeals: FETCH_MEALS,
    }),
    async activeFilter() {
      this.isActiveFilter = !this.isActiveFilter;
      this.filter.hidden = this.isActiveFilter;
      if (this.isActiveFilter === true) {
        this.filter.value = {};
        await this.fetch();
      }
      appLocalStorage.setItem('filters', Object.assign(this.initStorage, { storage: this.filter }));
    },
    async searchFn() {
      if (this.filter.value['ingredient.title'] == '') {
        delete this.filter.value['ingredient.title'];
      }
      await this.fetch({ filter: this.filter.value });
      appLocalStorage.setItem('filters', Object.assign(this.initStorage, { storage: this.filter }));
    },
    // Фильтр по категории
    async searchCategoryFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString } } : {};
      await this.fetchCategories(filter);
      cb(this.categories);
    },
    async selectCategoryFn() {
      await this.fetch({ filter: this.filter.value });
      appLocalStorage.setItem('filters', Object.assign(this.initStorage, { storage: this.filter }));
    },
    async clearSearchCategory() {
      if (this.filter.value['ingredient.category.title'] == '') {
        delete this.filter['ingredient.category.title'];
        await this.fetch({ filter: this.filter.value });
        appLocalStorage.setItem(
          'filters',
          Object.assign(this.initStorage, { storage: this.filter })
        );
      }
    },
    emptyFilter() {
      return !Object.keys(this.filter.storage).length === 0;
    },
  },
};
</script>
